export type Crumb = {
    label: string
    path?: string
    to: string
    icon?: string
}

export default function useBreadcrumbs() {
    const route = useRoute();
    const home = {
        label: 'Home',
        path: '/',
        to: '/',
        icon: 'front/home'
    }
    const breadcrumbs = useState('breadcrumbs', () : Crumb[] => {
        return [home]
    });

    const setCrumb = (crumbs: Crumb[]) => {
        breadcrumbs.value = [home, ...crumbs];
    }

    return {
        setCrumb,
        breadcrumbs
    }
}
